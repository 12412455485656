



























































































































































import Vue from 'vue';

// icons
import { mdiEye, mdiEyeOff, mdiAlertCircle, mdiCheckCircle } from '@mdi/js';

import i18n from '../i18n';

export default Vue.extend({
  props: ['token'],
  data: () => {
    return {
      mdiEye,
      mdiEyeOff,
      mdiAlertCircle,
      mdiCheckCircle,
      valid: false,
      newPassword: '',
      newPasswordToggle: false,
      confirmPassword: '',
      confirmPasswordToggle: false,
      confirmPasswordError: '',
      user: null,
      // global isLoading
      isLoading: false,
      // on submit new password
      isResetting: false,
      isSuccess: null,

      alert: {
        message: '',
      },

      requiredRule: (v: string) => !!v || i18n.t('requiredField').toString(),
    };
  },
  async beforeMount() {
    this.isLoading = true;
    try {
      const res = await this.$http.auth.resetPassword(this.$route.params.token);
      this.user = res;
    } catch (error) {
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async submit() {
      try {
        this.isResetting = true;
        this.isSuccess = await this.$http.auth.resetPasswordConfirmation({
          token: this.$route.params.token,
          password: this.newPassword,
        });

        if (this.isSuccess) {
          this.alert.message = 'forgotPassword.success';
          return;
        } else {
          this.alert.message = 'forgotPassword.invalidOrExpired';
          return;
        }
      } catch (error) {
      } finally {
        this.isResetting = false;
        (this.$refs.resetPasswordForm as any).reset();
      }
    },
    onInputConfirmPassword(value: string): void {
      this.confirmPasswordError = '';

      // one of the fields is not filled, nothing to do
      if (this.confirmPassword === '' || this.newPassword === '') return;

      this.confirmPasswordError =
        this.confirmPassword === this.newPassword
          ? ''
          : this.$i18n
              .t('users.changePassword.confirmPasswordError')
              .toString();
    },
  },
  computed: {
    RequiredRule() {
      return (v: string | null | undefined) => !!v || this.$t('requiredField');
    },
  },
});
